import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SocialProfile from "../../components/SocialProfile/SocialProfile"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
  IoLogoGithub,
  IoLogoRss,
} from "react-icons/io"
import { FaPatreon } from "react-icons/fa"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"
import Zoom from "react-reveal/Zoom"

const SocialLinks = [
  {
    icon: <IoLogoFacebook />,
    url: "#",
    tooltip: "Facebook",
  },
  {
    icon: <IoLogoInstagram />,
    url: "https://www.instagram.com/less.than.epsilon/",
    tooltip: "Instagram",
  },
  {
    icon: <IoLogoTwitter />,
    url: "https://twitter.com/zhi_han13",
    tooltip: "Twitter",
  },
  {
    icon: <IoLogoLinkedin />,
    url: "#",
    tooltip: "LinkedIn",
  },
  {
    icon: <IoLogoGithub />,
    url: "https://github.com/hanzhihua72/",
    tooltip: "Github",
  },
  {
    icon: <IoLogoRss />,
    url: "https://lessthanepsilon.net/rss.xml",
    tooltip: "RSS Feed",
  },
  {
    icon: <FaPatreon />,
    url: "https://patreon.com/lessthanepsilon",
    tooltip: "Patreon",
  },
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.png/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      <AboutPageTitle>
        <h2>About Less Than Epsilon</h2>
        {/*
        <p>
          Physics is often presented poorly in the media and the general public,
          either presented as magic or sometimes just plain wrong. Quantum
          mechanics is often presented as arcane and mystique when it has been
          experimentally verified for over 80 years now. However, things only
          seem like magic if you don't know the rules of the game.
        </p>
        <p>
          The truth about physics is that it is almost impossible to talk
          physics without understanding the math behind it, and so many people
          might feel that they are too dumb for physics because they don't know
          the math. Trying to do physics without any math is like trying to
          drive a car without any gas. Unfortunately, a lot of people hate
          mathematics, most likely due to having a bad math teacher. Concepts in
          math tend to build on each other a lot, so having a shaky foundation
          makes it hard to learn advanced material. All of these factors make
          physics inaccessible to most people.
        </p>
        */}
        <Zoom>
          <p>
            This blog is intended to present serious mathematics alongside
            physics while trying to remain fun. From Quantum Field Theory to
            General Relativity, we will explore the topics of mathematical
            physics as faithful as possible. Using a combination of animations
            and visuals, I try to strike a balance: presenting information as
            simple as possible, but no simpler.
          </p>
        </Zoom>
      </AboutPageTitle>

      <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
        {/*
        <iframe
          src={withPrefix("wavefunction.html")}
          height="600px"
          width="100%"
          allowFullScreen
          frameBorder="0"
        />
        */}
      </AboutImage>

      <AboutDetails>
        <h2>About Zhi</h2>
        <blockquote>
          Life is trivial and left as an exercise to the reader.
        </blockquote>

        <p>
          I'm Zhi Han, currently in 4th-year Mathematical Physics at the
          University of Alberta. This blog is meant to be all about visual math
          and physics. My interests are in Differential Geometry, Quantum
          Computation, Machine Learning, and Quantum Information in High Energy
          Physics.
        </p>

        <p>
          I primarily learned physics from a combination of reading textbooks
          and through the internet. ​I was inspired to make Less than Epsilon by
          3blue1brown, minutephysics, viascience, Welsh Labs, Kurzgesagt,
          Physics by Eugene Khutoryansky, Looking Glass Universe, Numberphile,
          CGP Grey, VSauce, vihart, Veritasium, PBS Infinite Series, PBS Space
          Time, math3ma, eigenchris, Pretty Much Physics, undefined behavior,
          Professor David Tong's lecture notes, and the manim discord.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
